<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Modifier un Préstataire",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Modifier un Préstataire",
      supplierForm: {
        uuid: null,
        supplierCodePrest:"",
        supplierName: "",
        supplierRaisonSocial: "",
        supplierCapitalSocial: "",
        supplierAddress: "",
        supplierAddress1: "",
        supplierAddress2: "",
        supplierNif: "",
        supplierRC: "",
        supplierCodeActivite: "",
        supplierStatutEntreprise: "",
        supplierMaisonName: "",
        supplierMaisonCordonnees: "",
        supplierResponsableName: "",
        supplierResponsableCordonnees: "",
      },
      types: [
        {
          id: "catering",
          name: "Full Catering",
        },

        {
          id: "Maintenance",
          name: "Maintenance",
        },

        {
          id: "gardening",
          name: "Espace Vert",
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
          {
            text: "Contrats",
            href: "/",
          },
          {
            text: "Modifier un prestataire",
            active: true,
          },
        ],
    };
  },

   validations: {
    supplierForm: {
      ///general infos
        suplierFname: { required },
        suplierLname: { required },
        suplierEmail: { required },
        suplierPhone: { required },
        suplierControleDes: { required },
        suplierCodeSys: { required },
        suplierNomMaison: { required },
        suplierCordMaison: { required },
      ///regular infos
        suplierDes: { required },
        suplierRaisonSocial: { required },
        suplierCapitalSocial: { required },
        suplierAdress1: { required },
        suplierAdress2: { required },
        suplierAdress3: { required },
        suplierFormJur: { required },
        suplierStatutEnt: { required },
        suplierCodeAct: { required },
        /////staff infos
        suplierGerantFname: { required },
        suplierGerantLname: { required },
        suplierDGFFname: { required },
        suplierDGFLname: { required },
        suplierComFname: { required },
        suplierComLname: { required },
        suplierAdminFname: { required },
        suplierAdminLname: { required },
    },
  },

  mounted() {
    this.contractForm.uuid = this.$route.params.uid;
    this.fetchContractDetails();
  },

  created() {
    this.fetchSuppliersList();
  },

  methods: {
    fetchSuppliersList() {
      this.$http
        .post("/contracts/suppliers/list")
        .then((res) => (this.suppliers = res.data.original.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchContractDetails() {
      var _this = this;
      this.$http
        .post(
          "/contracts/suppliers/getSingleSupplierData/" + this.supplierForm.uuid
        )

        .then((res) => {
          var status = res.data.original.status;
          var supplier = res.data.original.data.contract;
          switch (status) {
            case 200:
               _this.supplierForm.supplierName = supplier.name;
              _this.supplierForm.supplierCodePrest = supplier.code_prestatire;
              _this.supplierForm.supplierRaisonSocial = supplier.raison_social;
              _this.supplierForm.supplierCapitalSocial = supplier.capital_social;
              _this.supplierForm.supplierAddress = supplier.adress1;
              _this.supplierForm.supplierAddress1 = supplier.adress2;
              _this.supplierForm.supplierAddress2 = supplier.adress3;
              _this.supplierForm.supplierNif = supplier.nif;
              _this.supplierForm.supplierRC = supplier.rc;
              _this.supplierForm.supplierCodeActivite = supplier.code_activite;
              _this.supplierForm.supplierStatutEntreprise = supplier.statut_entreprise;
              _this.supplierForm.supplierMaisonName = supplier.maison_name;
              _this.supplierForm.supplierMaisonCordonnees = supplier.maison_cordonnees;
              _this.supplierForm.supplierResponsableName = supplier.responsable_name;
              _this.supplierForm.supplierResponsableCordonnees = supplier.responsable_cordonnees;
            
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http
        .post("/contracts/suppliers/update", this.supplierForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "base.contracts.suppliers.index" });
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="form-group row "> 
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >Code Prestataire</label
                >
                  <input
                    v-model="supplierForm.supplierCodePrest"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierCodePrest.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierCodePrest.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierCodePrest.required"
                      >Le Code Prestataire est obligatoire.</span
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >Désignation</label
                >
                  <input
                    v-model="supplierForm.supplierName"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierName.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierName.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierName.required"
                      >Le nom de prestataire est obligatoire.</span
                    >
                  </div>
                </div>
              </div>
              <div class="form-group row">
                
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >Raison Social</label
                >
                  <input
                    v-model="supplierForm.supplierRaisonSocial"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierRaisonSocial.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierRaisonSocial.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierRaisonSocial.required"
                      >Le Raison Social est obligatoire.</span
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >Capital Social</label
                >
                  <input
                    v-model="supplierForm.supplierCapitalSocial"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierCapitalSocial.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierCapitalSocial.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierCapitalSocial.required"
                      >Le Capital Social est obligatoire.</span
                    >
                  </div>
                </div>
              </div>
              <div class="form-group row">
                
                <div class="col-lg-4">
                  <label for="projectname" class="col-form-label col-lg-12"
                  >Adresse 1</label
                >
                  <input
                    v-model="supplierForm.supplierAddress"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierAddress.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierAddress.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierAddress.required"
                      >L'adresse est obligatoire.</span
                    >
                  </div>
                </div>
                <div class="col-lg-4">
                  <label for="projectname" class="col-form-label col-lg-8"
                  >Adresse 2</label
                >
                  <input
                    v-model="supplierForm.supplierAddress1"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierAddress1.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierAddress1.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierAddress1.required"
                      >L'adresse 2 de prestataire est obligatoire.</span
                    >
                  </div>
                </div>
                <div class="col-lg-4">
                  <label for="projectname" class="col-form-label col-lg-8"
                  >Adresse 3</label
                >
                  <input
                    v-model="supplierForm.supplierAddress2"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierAddress2.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierAddress2.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierAddress2.required"
                      >L'adresse 3 de prestataire est obligatoire.</span
                    >
                  </div>
                </div>
              </div>

              <div class="form-group row">
                
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >NIF</label
                >
                  <input
                    v-model="supplierForm.supplierNif"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierNif.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierNif.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierNif.required"
                      >Le NIF est obligatoire.</span
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >RC</label
                >
                  <input
                    v-model="supplierForm.supplierRC"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierRC.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierRC.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierRC.required"
                      >Le RC est obligatoire.</span
                    >
                  </div>
                </div>
              </div>
               <div class="form-group row ">
                
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >Code activités</label
                >
                  <input
                    v-model="supplierForm.supplierCodeActivite"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierCodeActivite.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierCodeActivite.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierCodeActivite.required"
                      >Le Code activités est obligatoire.</span
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >Statut entreprise</label
                >
                  <input
                    v-model="supplierForm.supplierStatutEntreprise"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierStatutEntreprise.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierStatutEntreprise.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierStatutEntreprise.required"
                      >Le Statut entreprise est obligatoire.</span
                    >
                  </div>
                </div>
              </div>
               <div class="form-group row">
                
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >Nom de la Maison mère</label
                >
                  <input
                    v-model="supplierForm.supplierMaisonName"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierMaisonName.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierMaisonName.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierMaisonName.required"
                      >Le Nom de la Maison mère est obligatoire.</span
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >coordonnées de la Maison mère</label
                >
                  <input
                    v-model="supplierForm.supplierMaisonCordonnees"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierMaisonCordonnees.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierMaisonCordonnees.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierMaisonCordonnees.required"
                      >Les coordonnées de la Maison mère est obligatoire.</span
                    >
                  </div>
                </div>
              </div>

              <div class="form-group row">
                
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >Nom de responsables de l'entreprise</label
                >
                  <input
                    v-model="supplierForm.supplierResponsableName"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierResponsableName.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierResponsableName.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierResponsableName.required"
                      >Nom de responsables est obligatoire.</span
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <label for="projectname" class="col-form-label col-lg-5"
                  >coordonnées de responsables de l'entreprise</label
                >
                  <input
                    v-model="supplierForm.supplierResponsableCordonnees"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.supplierForm.supplierResponsableCordonnees.$error,
                    }"
                    placeholder=""
                  />
                  <div
                    v-if="submitted && $v.supplierForm.supplierResponsableCordonnees.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.supplierForm.supplierResponsableCordonnees.required"
                      >Les coordonnées de responsable est obligatoire.</span
                    >
                  </div>
                </div>
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>